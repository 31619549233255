import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { getFirestore, collection } from 'firebase/firestore'
// ... other firebase imports

import firebaseConfig from './firebaseConfig.json';

export const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

// import { useFirebaseAuth } from 'vuefire';
// const auth = useFirebaseAuth() // only exists on client side

// TODO: Synch login status.
// const pingMe = async () => {
//     try {
//         const resp = await axios.get(route('auth.login.synch.firebase'))
//             .then(response => {
//                 return response;
//             })
//         console.log(resp.data);
//     } catch (err) {
//         // Handle Error Here
//         console.error(err);
//     }
// };

function initApp() {
    onAuthStateChanged(auth, (user) => {

        // console.error('Firebase.onAuthStateChanged', {
        //     route: route().current(),
        //     user: user,
        //     app_user_id: getCookie('app_user_id'),
        //     app_user_coupon: getCookie('app_user_coupon')
        // });

        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            // const uid = user.uid;
            // User is signed in.
            // var displayName = user.displayName;
            const email = user.email;
            // var emailVerified = user.emailVerified;
            // var photoURL = user.photoURL;
            // var isAnonymous = user.isAnonymous;
            const uid = user.uid;
            // var providerData = user.providerData;

            setCookie('app_user_id', uid, 2629800 * 13);
            setCookie('app_user_email', email, 2629800 * 13);

            // const pinged = pingMe();
            // console.error('pinged', pinged);

        } else {
            // User is signed out
            if (getCookie('app_user_id')) {
                setCookie('app_user_id', null, -1);
                setCookie('app_user_email', null, -1);
            }
            if (getCookie('app_user_coupon')) {
                setCookie('app_user_coupon', null, -1);
                setCookie('app_user_coupon_scope', null, -1);
            }
        }
    });
}

window.onload = function () {
    initApp()
};
