
window.getCookie = (name) => {
    // console.error('[Cookie]', name);

    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

    return match && match.length ? match[2] : null;
};

window.setCookie = (name, value, expires) => {
    // console.error('[Cookie]', name, value);

    // document.cookie = 'rgpd=approved; expires=Fri, 3 Aug 2020 20:47:11 UTC; path=/';
    // var nd = new Date();
    // var d = new Date((nd.getTime() + 1000) * expires);
    // document.cookie = name + '=' + value + '; expires=' + d.toGMTString() + '; path=/;SameSite=Lax;';

    var nd = new Date();
    var d = nd.setSeconds(nd.getSeconds() + expires);
    d = new Date(d).toGMTString();
    // var domain = 'meditbe.com';

    // document.cookie = `${name}=${value}; expires=${d}; domain=${domain};path=/;SameSite=Lax;`;
    document.cookie = `${name}=${value}; expires=${d}; path=/;SameSite=Lax;`;
};